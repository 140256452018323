import styled, { css } from 'styled-components'
import { colors } from '../../data/constants'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
  input[type="checkbox"] {
  display: none;
  }
  ${({ isChecked }) => isChecked
    ? css`background-color: ${colors.primary_default}; color: ${colors.white}`
    : css`background-color: #ffe29a; color: ${colors.primary_default}`};
  border-radius: 4px;
  `
/* ${({ isActive, shouldBeSelected }) => !isActive && (shouldBeSelected ? css`background: #eee;` : css`background: #fff;`)} */

export const Rdio = styled.div`
  background-color: #fff;
  width: 24px;
  height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 13px;
  div {
    width:14px;
    height: 14px;border-radius: 50%;
    background-color: ${({ isCheck }) => isCheck ? colors.secondary_default : 'white'};
    
  }
`
export const Multi = styled.div`
  background-color: #fff;
  width: 24px;
  height:24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isCheck }) => isCheck && css`background: url('/icns/icon-check.svg') no-repeat #fff;`}
`

export const Input = styled.input`
  background-color: ${props => props.isCheck ? 'black' : 'white'};
`
export const CheckBox = styled.span`
  width: 10px;
  height: 10px;
  border: 2px solid black;
  display: inline-block;
  background-color: ${props => props.isChecked ? 'black' : 'white'};
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 12px;
  /* padding: 15px; */
`
