import React, { useState } from 'react'

import { motion } from 'framer-motion'
import { usePositionReorder } from '../../hooks/use-position-reorder'
import { useMeasurePosition } from '../../hooks/use-mesure-position'
import './classify.css'
import PropTypes from 'prop-types'
import { shuffle } from '../../utils'
import Button from '../Button/Button'
import HintBt from '../HintBt/HintBt'
import Hint from '../Hint/Hint'

import { ReactComponent as Handle } from '../../icns/icon-drag.svg'
import { useSelector } from 'react-redux'
import { selectCommonKeys } from '../../store/appSlice'

export default function Classify ({ _items, body, title, nextPage, cta1Txt, setSuccess, setQpoints, updatePoints, hint }) {
  const answers = shuffle(JSON.parse(JSON.stringify(_items)))
  const [order, updatePosition, updateOrder] = usePositionReorder(answers)
  const [hintOpen, sethintOpen] = useState(false)
  const [_hasOpenHint, setHasOpenHint] = useState(false)
  const cmn = useSelector(selectCommonKeys)
  function consumeCb () {
    setHasOpenHint(true)
  }
  function verify () {
    const max = order.length
    let score = 0
    order.forEach(({ order }, i) => {
      if (order === i + 1) ++score
    })
    let pts
    if (score === max) {
      pts = _hasOpenHint ? 60 : 80
      setSuccess(2)
    } else if (score > 0) {
      pts = _hasOpenHint ? 20 : 40
      setSuccess(1)
    } else {
      pts = _hasOpenHint ? 0 : 20
    }
    setQpoints(_hasOpenHint ? pts + 20 : pts)
    updatePoints(pts)
    nextPage()
  }

  function openHint () {
    sethintOpen(true)
    setHasOpenHint(true)
  }

  function closeHint () {
    sethintOpen(false)
  }

  return (
    <>
    {hint && <Hint hTxt={cmn.open_hint_warning} text={hint} isOpen={hintOpen} closeFunc={closeHint} consumeCb={consumeCb} cta1txt={cmn.open_hint_cta}/>}
    <div className="pgCont">
      <div className="pgBody">
      <h2 className='qHeadline'>{title}</h2>
      {body && <p>{body}</p>}
      <ul>
        {order.map((item, i) => (
          <Item
            key={item.order}
            i={i}
            text={item.text}
            updatePosition={updatePosition}
            updateOrder={updateOrder}
          />
        ))}
      </ul>
      </div>
      <footer className="pgFooter">
        {hint && <div className="footerCell cellLeft">
          <HintBt onClick={openHint} />
        </div>}
        <div className="footerCell cellRight">
          <Button text={cta1Txt || 'next'} onClick={verify}/>
        </div>
      </footer>
    </div>
    </>
  )
}

function Item ({ i, updatePosition, updateOrder, text }) {
  const [isDragging, setDragging] = useState(false)

  const ref = useMeasurePosition((pos) => updatePosition(i, pos))

  return (
    <li
      style={{
        padding: 0,
        // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
        zIndex: isDragging ? 3 : 1
      }}
    >
      <motion.div
        ref={ref}
        layout
        initial={false}
        className='classItem unselectable'
        style={{
          borderRadius: 5,
          boxShadow: '0px 2px 2px rgba(0,0,0,0.20)'
        }}
        whileHover={{
          scale: 1.03,
          boxShadow: '0px 3px 3px rgba(0,0,0,0.15)'
        }}
        whileTap={{
          scale: 1.06,
          boxShadow: '0px 5px 5px rgba(0,0,0,0.1)'
        }}
        drag="y"
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        onViewportBoxUpdate={(_viewportBox, delta) => {
          isDragging && updateOrder(i, delta.y.translate)
        }}
      ><Handle /> {text}</motion.div>
    </li>
  )
}

Item.propTypes = {
  i: PropTypes.number.isRequired,
  // height: PropTypes.number.isRequired,
  updateOrder: PropTypes.func.isRequired,
  updatePosition: PropTypes.func.isRequired,
  text: PropTypes.string
}

Classify.propTypes = {
  _items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired
  }).isRequired),
  title: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
  cta1Txt: PropTypes.string,
  setSuccess: PropTypes.func.isRequired,
  body: PropTypes.string,
  setQpoints: PropTypes.func.isRequired,
  hint: PropTypes.string,
  updatePoints: PropTypes.func
}
