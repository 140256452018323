import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import NextBt from '../../Components/NextBt/NextBt'
import McqItem from '../../Components/McqItem'
import { getItems } from '../../utils'
import Pictures from '../../Components/Pictures/Pictures'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCustomFb
} from '../../store/questionSlice'
import './Mcq.css'
import Hint from '../../Components/Hint/Hint'
import HintBt from '../../Components/HintBt/HintBt'
import Button from '../../Components/Button/Button'

import { selectCommonKeys } from '../../store/appSlice'

/**
 * update the choice object with the given id with the provided data
 *
 * @param {number} id id of the item to update
 * @param {[{id: number, isChecked: boolean}]} data the data to update, object will be merged with the right choice
 * @param {array} choices array of choices
 * @returns {array} updated array of choices
 */
function updateCheckMcqItem (id, isChecked, choices) {
  const newState = choices.map(choice => {
    if (choice._isRadio) {
      let checked
      if (choice.id === id) checked = true
      else checked = false
      return { ...choice, isChecked: checked || false }
    } else return choice.id === id ? { ...choice, isChecked } : choice
  })
  return newState
}

/**
 * Mcq component
 *
 * @param {{
 *  _id: string,
 *  _component: string,
 *  title: stting,
 *  body:string,
 *  pct1:string,
 *  pct2:string,
 *  _items:[{
 *    text:string,
 *    _isSelected:boolean
 *  }]
 * }} { _id, _component, title, body, pct1, pct2, _items }
 * @returns {}
 */

function Mcq ({
  _component,
  title,
  body,
  pct1,
  _items,
  nextPage,
  setSuccess,
  hint,
  setQpoints,
  updatePoints,
  cta1Txt,
  _isAlwaysRight,
  alt1,
  skipPoint,
  nextQuestion,
  ...rest
}) {
  const [hintOpen, sethintOpen] = useState(false)
  const [_hasOpenHint, setHasOpenHint] = useState(false)
  const [isValid, setValid] = useState(false)
  const [_isActive, setActive] = useState(true)
  const [curChoices, setcurChoices] = useState(getItems(JSON.parse(JSON.stringify(_items)), _component))
  const dispatch = useDispatch()
  const cmn = useSelector(selectCommonKeys)
  function onUpdateChoice (id, val) {
    const newState = updateCheckMcqItem(id, val, curChoices)
    const isValid = newState.map(item => item.isChecked).reduce((accumulator, currentValue) => accumulator === true || currentValue === true)
    setValid(isValid)
    setcurChoices(newState)
  }
  function consumeCb () {
    setHasOpenHint(true)
  }
  function verify () {
    const perfectAnswer = curChoices.length
    let gAnswer = 0
    curChoices.forEach(({ _shouldBeSelected, isChecked, feedbackCustom }, index) => {
      if (isChecked) {
        dispatch(setCustomFb(feedbackCustom))
      }
      if (_shouldBeSelected === isChecked || (_shouldBeSelected === false && isChecked === false)) {
        ++gAnswer
      }
    })
    let pts
    if (gAnswer === perfectAnswer || _isAlwaysRight) {
      pts = _hasOpenHint ? 60 : 80
      setSuccess(2)
    } else if (gAnswer > 0 && _component !== 'mcq-single') {
      pts = _hasOpenHint ? 20 : 40
      setSuccess(1)
    } else {
      pts = _hasOpenHint ? 0 : 20
    }
    setActive(false)
    // special case for question with no "bad answer" skips the 'no' to the next q instead of the result page
    if (skipPoint && pts === 20) {
      updatePoints(0)
      nextQuestion()
    } else {
      setQpoints(_hasOpenHint ? pts + 20 : pts)
      updatePoints(pts)
      nextPage()
    }
  }

  function openHint () {
    sethintOpen(true)
    setHasOpenHint(true)
  }

  function closeHint () {
    sethintOpen(false)
  }

  return (
    <>
    <Hint
    hTxt={cmn.open_hint_warning}
    text={hint}
    isOpen={hintOpen}
    cta1txt={cmn.open_hint_cta}
    closeFunc={closeHint}
    consumeCb={consumeCb}/>
    <div className='pgCont'>
      <div className='pgBody'>
        <h2 className='qHeadline'>{title}</h2>
        {pct1 && (
          <Pictures pct1={pct1} alt1={alt1} />
        )}
        {body && <p>{body}</p>}
        {curChoices.map((item, i) => <McqItem key={`qitems-${i}`} {...item} onUpdateChoice={onUpdateChoice} _isActive={_isActive} type={_component}/>)}
      </div>
      <footer className="pgFooter">
        {hint && <div className="footerCell cellLeft">
          <HintBt onClick={openHint} />
        </div>}
        <div className="footerCell cellRight">
          <Button text={cta1Txt || 'next'} onClick={verify} disabled={!isValid}/>
        </div>
      </footer>
    </div>
    </>
  )
}

Mcq.propTypes = {
  _id: PropTypes.string,
  _component: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  pct1: PropTypes.string,
  alt1: PropTypes.string,
  pct2: PropTypes.string,
  _items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    _shouldBeSelected: PropTypes.bool.isRequired,
    feedback: PropTypes.string
  }).isRequired).isRequired,
  nextPage: PropTypes.func,
  nextQuestion: PropTypes.func,
  setSuccess: PropTypes.func.isRequired,
  hint: PropTypes.string,
  setQpoints: PropTypes.func.isRequired,
  cta1Txt: PropTypes.string,
  _isAlwaysRight: PropTypes.bool,
  skipPoint: PropTypes.bool,
  updatePoints: PropTypes.func
}

export default Mcq
