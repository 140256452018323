/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
// import NameScreen from './Screens/NameScreen/NameScreen'
import QuestionScreen from './Screens/QuestionScreen/QuestionScreen'
import QuizzComp from './Screens/QuizzScreen'
import IndexScreen from './Screens/IndexScreen/IndexScreen'
import { useDispatch, useSelector } from 'react-redux'
import { selectLang, setCommonKeys, start } from './store/appSlice'
import MenuOverlay from './Components/MenuOverlay/MenuOverlay'
import Debug from './Components/Debug/Debug'
// import Hint from './Components/Hint/Hint'
import './App.css'
import './schocoFont.css'
import './fonts/schoko.ttf'
import './fonts/OpenSans-Bold.ttf'
import './fonts/OpenSans-BoldItalic.ttf'
import './fonts/OpenSans-Regular.ttf'
import './fonts/OpenSans-Italic.ttf'
import './fonts/ShareTechMono-Regular.ttf'
import FinalScreen from './Screens/FinalScreen/FinalScreen'
import PrefScreen from './Screens/PrefScreen/PrefScreen'
import SplashScreen from './Screens/SplashScreen/SplashScreen'
import { prepareQ, selectIsQuizzReady } from './store/questionSlice'

const DBG = process.env.NODE_ENV === 'development'

const App = () => {
  const dispatch = useDispatch()
  // const dimentions = useRef({ width: window.innerWidth, height: window.innerHeight })
  const isQuizzReady = useSelector(selectIsQuizzReady)
  const currLang = useSelector(selectLang)
  const [overlayOpen, setOverlayOpen] = useState(false)
  // const [curPage, setCurrPage] = useState(false)
  useEffect(() => {
    const fetchQ = async () => {
      const data = await fetch(`/data/appData${currLang}.json`)
      return data.json()
    }
    const fetchTrad = async () => {
      const tradData = await fetch('/data/commonKeys.json')
      return tradData.json()
    }

    fetchQ().then((data) => {
      fetchTrad().then((trad) => {
        dispatch(prepareQ(data))
        dispatch(setCommonKeys(trad))
        dispatch(start())
      })
    }).catch((err) => {
      console.log(err)
    })
  }, [currLang])

  function closeOverLay () {
    setOverlayOpen(false)
  }

  function openOverlay () {
    setOverlayOpen(!overlayOpen)
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{'Schoko-Quiz'}</title>
      </Helmet>
      <div className='appContainer'>
        {/* <Hint text='temp'/> */}
        {DBG && <Debug />}
        {isQuizzReady && <Router basename={process.env.PUBLIC_URL}>
          <MenuOverlay
            close={closeOverLay}
            isOpen={overlayOpen}/>
          <Route
            render={({ location }) => (
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={SplashScreen} />
                  <Route exact path="/onboarding" component={IndexScreen} />
                  <Route exact path="/questions/:floor?/:q?" children={<QuestionScreen openMenu={openOverlay}/>} />
                  <Route exact path="/quizz/:floor?/:q?" children={<QuizzComp openMenu={openOverlay}/>} />
                  <Route exact path="/final" children={<FinalScreen openMenu={openOverlay}/>} />
                  <Route exact path="/profile" children={<PrefScreen openMenu={openOverlay}/>} />
                  {/* <Route exact path="/class" children={<TestOutOfOrder setPoints={updatePoints} points={points} answers={answers}/>} /> */}
                  <Route path="*" component={NoMatch} status={404}/>
                </Switch>
              </AnimatePresence>
            )}
          />
        </Router>}
      </div>
    </HelmetProvider>
  )
}

const NoMatch = () => (<div>hoo no, no match for this route get back <a href="/">home</a></div>)

export default App
