import React from 'react'
// import styled from 'styled-components'
import PropTypes from 'prop-types'
import './Splash.css'
import splashImg from '../../imgs/splash.png'
import logo1 from '../../imgs/logo_1.png'
import logo2 from '../../imgs/logo_2.png'
import logo3 from '../../imgs/logo_3.png'
import logo4 from '../../imgs/logo_4.png'
import Button from '../Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeLang,
  selectLang,
  updateLocale
} from '../../store/appSlice'
import { prepareQ } from '../../store/questionSlice'
const Splash = ({ cta1act, title, langPage, goToLang }) => {
  const lang = useSelector(selectLang)
  const dispatch = useDispatch()

  function toEn () {
    if (lang !== 'en') {
      fetch('/data/appDataen.json')
        .then((res) => {
          return res.json()
        })
        .then(data => {
          dispatch(prepareQ(data))
          dispatch(changeLang('en'))
          dispatch(updateLocale('en'))
          cta1act()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      cta1act()
    }
  }
  function toDe () {
    console.log('toDe clicked', lang)
    if (lang !== 'de') {
      fetch('/data/appDatade.json')
        .then((res) => {
          return res.json()
        })
        .then(data => {
          dispatch(prepareQ(data))
          dispatch(changeLang('de'))
          dispatch(updateLocale('de'))
          cta1act()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      cta1act()
    }
  }
  return (
    <div onClick={goToLang}>
      <div className="splashImgCont">
        <img src={splashImg} alt="" className='splashImg'/>
        <div className="splashTtlCont">
          <div className="splashTtl">{title}</div>
        </div>
      </div>
      {langPage
        ? <div className='langCont'>
          <Button text='Auf Deutsch spielen' onClick={toDe}/>
          <Button text='Play in English' onClick={toEn}/>
        </div>
        : <div className="splashLogoCont">
        <div className="splashLine">
          <img src={logo1} alt=""/><img src={logo2} alt=""/>
        </div>
        <div className="splashLine">
          <img src={logo3} alt=""/>
          <img src={logo4} alt=""/>
        </div>
      </div>
      }

    </div>
  )
}

Splash.propTypes = {
  cta1act: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  goToLang: PropTypes.func.isRequired,
  langPage: PropTypes.bool.isRequired
}

export default Splash
