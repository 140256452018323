import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Input, Label, Rdio, Multi } from './mcqItem.css.js'
const McqItem = ({ id, text, isValid, _isActive, isChecked, feedback, _shouldBeSelected, onUpdateChoice, type }) => {
  const checkbox = useRef(null)
  function updateCheck () {
    onUpdateChoice(id, checkbox.current.checked)
  }

  return (
    <Wrapper
      isValid={isValid}
      isChecked={isChecked}
      isActive={_isActive}
      shouldBeSelected={_shouldBeSelected}
      // className={`${isChecked ? 'isChecked ' : ''}${isActive ? 'notAnswered' : (isValid ? 'answerValid' : 'answerInvalid')}`} onClick={updateCheck}
    >
      <Label className="checkbox">
        <Input
          type="checkbox"
          defaultChecked={isChecked}
          disabled={!_isActive}
          name="checked"
          onChange={(updateCheck)}
          ref={checkbox}
        />
        {(type === 'mcq-single' || type === 'mcq-custom')
          ? <Rdio isCheck={isChecked}><div/></Rdio>
          : <Multi isCheck={isChecked} />
        }
          {text} {feedback && !_isActive && !isValid && feedback} {(!_isActive && (_shouldBeSelected ? '✔️' : '❌'))}
        {/* <CheckBox isChecked={isChecked} onClick={updateCheck} /> */}
      </Label>
    </Wrapper>
  )
}

McqItem.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  _isActive: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  feedback: PropTypes.string,
  _shouldBeSelected: PropTypes.bool.isRequired,
  onUpdateChoice: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default McqItem
