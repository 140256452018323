import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import './Hint.css'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'

const containerVariants = {
  hidden: { opacity: 0, zIndex: -1 },
  show: {
    opacity: 1,
    zIndex: 60,
    transition: {
      staggerChildren: 5.5
    }
  }
}

export default function Hint ({ text, isOpen, title, closeFunc, hTxt, cta1txt, cta1act, consumeCb, pct, dlUrl }) {
  const [consumeHint, setConsumeHint] = useState(false)
  function showHint () {
    setConsumeHint(true)
    consumeCb()
  }
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate={isOpen ? 'show' : 'hidden'}
      className='hintCont'
    >
      <div className="hintBox">
        <div className="hintHead">
          <div className="hintTitle">{title || 'Hilfe'}</div>
          <button onClick={closeFunc} className="closeBt">
            <Icon type='close' />
          </button>
        </div>
        {hTxt
          ? <div>
          {consumeHint
            ? text
            : (
              <>
                {hTxt}
                <div>
                  <Button text={cta1txt} onClick={showHint}/>
                </div>
              </>
              )}
        </div>
          : <div>
          {text}
          {pct && <div className='hintPctCont'><img src={pct} alt=""/></div>}
          {dlUrl
            ? <a href={dlUrl} className="button dlBt profile" download><span className="icn-Icon-Download" /><span className='dlTxt'>{cta1txt}</span></a>
            : cta1txt && <Button text={cta1txt} profile floorBt onClick={cta1act}/>
          }
          {}
        </div>
        }

      </div>
    </motion.div>
  )
}

Hint.propTypes = {
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  ctaText: PropTypes.string,
  closeFunc: PropTypes.func,
  title: PropTypes.string,
  hTxt: PropTypes.string,
  cta1txt: PropTypes.string,
  consumeCb: PropTypes.func,
  pct: PropTypes.string,
  dlUrl: PropTypes.string,
  cta1act: PropTypes.func
}
