import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import QuizzComp from './QuizzScreen'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectFloorMenu, setCurrentQ
} from '../../store/questionSlice'

import { useParams, useHistory } from 'react-router-dom'

export default function QuizzScreen ({ openMenu }) {
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(false)
  const urlParams = useParams()
  const history = useHistory()
  const floorMenu = useSelector(selectFloorMenu)

  useEffect(() => {
    if (!urlParams.floor || !urlParams.q || isNaN(parseInt(urlParams.floor, 10)) || isNaN(parseInt(urlParams.q, 10)) || urlParams.floor > floorMenu.length || urlParams.q > floorMenu[urlParams.floor - 1]?.questions.length) {
      history.push('/quizz/1/0')
    } else {
      setIsValid(true)
      dispatch(setCurrentQ({ floor: parseInt(urlParams.floor, 10) - 1, q: parseInt(urlParams.q, 10) }))
    }
  }, [urlParams])

  if (!isValid) {
    return null
  }

  return (
    <QuizzComp openMenu={openMenu} urlParams={{ floor: parseInt(urlParams.floor, 10), q: parseInt(urlParams.q, 10) }}/>
  )
}

QuizzScreen.propTypes = {
  openMenu: PropTypes.func
}
