import React from 'react'
import PropTypes from 'prop-types'
import Pictures from '../../Components/Pictures/Pictures'

import Button from '../../Components/Button/Button'

function Photobooth ({ title, body, pct1, _items, nextPage, setSuccess, setQpoints, updatePoints, alt1, nextQuestion }) {
  function valYes () {
    setSuccess(2)
    setQpoints(80)
    updatePoints(80)
    nextPage()
  }

  function valNo () {
    setSuccess(0)
    setQpoints(0)
    updatePoints(0)
    nextQuestion()
  }

  return (
    <div className='pgCont'>
      <div className='pgBody'>
        <h2 className='qHeadline'>{title}</h2>
        {pct1 && (
          <Pictures pct1={pct1} alt1={alt1} />
        )}
        {body && <p>{body}</p>}
      </div>
      <footer className="pgFooter photoFooter socialLinks">
        <Button text={_items[0].text} onClick={valYes}/>
        <Button text={_items[1].text} nope onClick={valNo}/>
      </footer>
    </div>

  )
}

Photobooth.propTypes = {
  _id: PropTypes.string,
  _component: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  pct1: PropTypes.string,
  alt1: PropTypes.string,
  pct2: PropTypes.string,
  _items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    _shouldBeSelected: PropTypes.bool.isRequired,
    feedback: PropTypes.string
  }).isRequired).isRequired,
  nextPage: PropTypes.func,
  nextQuestion: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  hint: PropTypes.string,
  setQpoints: PropTypes.func.isRequired,
  cta1Txt: PropTypes.string,
  alwaysRight: PropTypes.bool,
  updatePoints: PropTypes.func
}

export default Photobooth
