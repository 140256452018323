import { createSlice } from '@reduxjs/toolkit'
import { changeQ, updatePoints, prepareQ } from './questionSlice'
import { getPlatform } from '../utils'

function prepareQuestionStatus (floors) {
  if (!floors || !Array.isArray(floors)) return false
  const filteredFloors = []
  floors.forEach(floor => {
    const filteredQs = floor.questions.map(q => q.isFinished || false)
    filteredFloors.push(filteredQs)
  })
  return filteredFloors
}

const platform = getPlatform()
const initialState = {
  lang: 'en',
  kakaoName: null,
  isFinished: false,
  isKakaoInvalid: false,
  currentQ: null,
  startTime: null,
  isOverTime: false,
  onboradingFinished: false,
  maxTime: 200,
  commonKeys: {},
  allCommon: {},
  os: platform,
  points: 0
}

export const slice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    updateKName: (state, action) => {
      state.kakaoName = action.payload
    },
    changeLang: (state, action) => {
      state.lang = action.payload
    },
    setCommonKeys: (state, action) => {
      const allKeys = Object.keys(action.payload)
      const commonKeys = {}
      allKeys.forEach(key => {
        commonKeys[key] = action.payload[key][state.lang]
      })
      state.commonKeys = commonKeys
      state.allCommon = action.payload
    },
    updateLocale: (state, action) => {
      console.log('updating locale')
      const newLocale = action.payload
      const allKeys = Object.keys(state.allCommon)
      const commonKeys = {}
      allKeys.forEach(key => {
        commonKeys[key] = state.allCommon[key][newLocale]
      })
      state.commonKeys = commonKeys
    },
    start: (state, action) => {
      if (!state.startTime) state.startTime = JSON.stringify(new Date())
      else {
        const diff = Math.abs(new Date() - Date.parse(state.startTime))
        state.isOverTime = diff > state.maxTime
      }
    },
    setObStatus: (state, action) => {
      state.onboradingFinished = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeQ, (state, action) => {
        state.currentQ = action.payload
      })
      .addCase(updatePoints, (state, action) => {
        console.log('point update', action.payload.pts)
        state.points = state.points + action.payload
      })
      .addCase(prepareQ, (state, action) => {
        // console.log('prepareQ', action)
        const filtered = prepareQuestionStatus(action.payload)
        state.questionStatus = filtered
      })
  }
})

export const { updateKName, start, setObStatus, changeLang, setCommonKeys, updateLocale } = slice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

export const selectKName = state => state.app.kakaoName
export const selectOS = state => state.app.os
export const selectApp = state => state.app
export const selectObStatus = state => state.app.onboradingFinished
export const selectLang = state => state.app.lang
export const selectCommonKeys = state => state.app.commonKeys

export default slice.reducer
