import React, { useEffect } from 'react'
// import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCustomFb,
  selectCustomFb
} from '../../store/questionSlice'
// import NextBt from '../../Components/NextBt/NextBt'
import Button from '../Button/Button'
import './ResultPage.css'
import { selectCommonKeys } from '../../store/appSlice'
const ResultPage = (props) => {
  console.log('result page props', props)
  const { success, feedback, feedbackPartial, feedbackFail, title, titlePartial, titleFail, points, nextQuestion, cta1Txt } = props
  const dispatch = useDispatch()
  const customFb = useSelector(selectCustomFb)
  const cmn = useSelector(selectCommonKeys)
  useEffect(() => {
    return () => {
      // componentwillunmount in functional component.
      // Anything in here is fired on component unmount.
      if (customFb) dispatch(setCustomFb(false))
    }
  }, [])
  return (
    <div className='pgCont'>
      <div className='pgBody'>
        <div className='pointsCont'>
          <span className='getCont'>{cmn.you_get}</span>
          <span className='points'>{points}</span>
          <span className='beanCount'>{cmn.cacao_beans}</span>
          {(success === 2 || success === 1 || customFb) && <img src="/pct/result.png" className='resultPct' alt=""/>}
          {success === 0 && <img src="pct/result_fail.png" className='resultPct' alt=""/>}
        </div>
        <h2>
          {(success === 2 || customFb) && title}
          {titlePartial && success === 1 && titlePartial}
          {((!titlePartial && success === 1) || success === 0) && titleFail}
        </h2>
        <p>
          {customFb || <>
              {success === 2 && feedback}
              {feedbackPartial && success === 1 && feedbackPartial}
              {((!feedbackPartial && success === 1) || success === 0) && feedbackFail}
            </>
          }
        </p>
      </div>
      <footer className='pgFooter'>
        <Button text={cta1Txt || 'next'} onClick={nextQuestion}/>
      </footer>
    </div>
  )
}

ResultPage.propTypes = {
  success: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  titlePartial: PropTypes.string,
  titleFail: PropTypes.string,
  feedback: PropTypes.string.isRequired,
  feedbackPartial: PropTypes.string,
  points: PropTypes.number.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  feedbackFail: PropTypes.string,
  cta1Txt: PropTypes.string
}

export default ResultPage
