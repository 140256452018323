import React from 'react'
import PropTypes from 'prop-types'
import './button.css'
import Icon from '../Icon/Icon'
export default function Button (props) {
  const { text, size, profile, cert, qr, fb, ig, nope, profileBt, floorBt, social, ...rest } = props
  return (
    <button
      type="button"
      className={['button', `button--${size}`, profile && 'profile', fb && 'social fb', ig && 'social ig', nope && 'nopeBt'].join(' ')}
      {...rest}
    >
      {cert && <Icon type='document'/>}
      {qr && <Icon type='link'/>}
      {fb && <Icon type='shareFacebook'/>}
      {ig && <Icon type='shareIg'/>}
      {floorBt && <Icon type='arrowRight'/>}
      {profileBt && <Icon type='user'/> }
      <span className="btTxt">{text}</span>
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  profile: PropTypes.bool,
  profileBt: PropTypes.bool,
  cert: PropTypes.bool,
  floorBt: PropTypes.bool,
  qr: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  social: PropTypes.bool,
  fb: PropTypes.bool,
  ig: PropTypes.bool,
  nope: PropTypes.bool
}

Button.defaultProps = {
  size: 'medium'
}
