import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectQuestions,
  updatePoints,
  selectFloorMenu
} from '../../store/questionSlice'
import { useHistory } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import QuestionDisplay from '../../Components/QuestionDisplay/QuestionDisplay'
import PageHeader from '../../Components/PageHeader/PageHeader'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { findFirstUnansweredQuestion } from '../../utils'
const hasBar = true
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

function QuestionScreen ({ openMenu }) {
  const urlParams = useParams()
  const qState = useSelector(selectQuestions)
  const floor = parseInt(urlParams.floor, 10)
  // const points = useSelector(selectPoints)
  const floorMenu = useSelector(selectFloorMenu)
  const dispatch = useDispatch()
  const [params, setParams] = useState(urlParams || { floor: 1, q: 1 })
  const currQData = qState.questions[parseInt(params.floor - 1)].questions[parseInt(params.q)]
  const history = useHistory()

  useEffect(() => {
    setParams(urlParams)
    // dispatch(changeQ(parseInt(params.q)))
  }, [params])

  if (!floorMenu[floor - 1]?.questions[urlParams.q]) {
    console.log('no q')
    const nextQ = findFirstUnansweredQuestion(floorMenu, floor)
    console.log(nextQ)
    if (nextQ) history.push(`/quizz/${nextQ.floor + 1}/${nextQ.q}`)
  } else {
    console.log('got a q')
    console.log(floorMenu[floor - 1].questions)
  }

  const currType = floorMenu[floor - 1].questions[urlParams.q].type

  function upPoints (pts) {
    let qType = false
    switch (currType) {
      case 'mcq-single':
      case 'mcq-multi':
      case 'classify':
        qType = 'question'
        break
      case 'photobooth':
        qType = 'photobooth'
        break
      default:
        break
    }
    dispatch(updatePoints({ pts, qType }))
  }
  if (currType === 'photobooth') {
    document.body.classList.add('photoBg')
  } else {
    document.body.classList.remove('photoBg')
  }
  const { title, type } = floorMenu[parseInt(params.floor - 1)].questions[parseInt(params.q)]
  const direction = 1
  return (
        <>
          <div className='pagesContainer'>
            {hasBar && <PageHeader qNum={parseInt(params.q)} title={title} type={type} openMenu={openMenu} />}
            <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    variants={variants}
                    initial="exit"
                    custom={direction}
                    animate="center"
                    exit="exit"
                    className='pageCont'
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 }
                    }}
                    key={`page-${params.q}`}>
                    <PageCont pages={currQData} updatePoints={upPoints}/>
                  </motion.div>
            </AnimatePresence>
          </div>
        </>
  )
}

export function PageCont ({ pages, updatePoints, nextQOb, finishQ }) {
  console.log('nextQOb in nextCont', nextQOb)
  const [qPoints, setQpoints] = useState(0)
  const [currPage, setCurrPage] = useState(0)
  const cPageData = pages[currPage]
  const [isOver, setOver] = useState(false)
  const [success, setSuccess] = useState(0)
  function nextPage () {
    if (currPage < pages.length - 1) {
      setCurrPage(currPage + 1)
    } else setOver(true)
  }

  return <>
    {/* <div>
      <div>points: {qPoints}</div>
      <div>currPage: {currPage}</div>
      <div>isOver: {isOver ? 'finished' : 'not finished'}</div>
      <div>success: {success}</div>
    </div> */}

    <QuestionDisplay {...cPageData}
      setOver={setOver}
      nextQOb={nextQOb}
      finishQ={finishQ}
      nextPage={nextPage}
      setSuccess={setSuccess}
      points={qPoints}
      setQpoints={setQpoints}
      updatePoints={updatePoints}
      success={success}
      isOver={isOver}
      />
  </>
}

QuestionScreen.propTypes = {
  openMenu: PropTypes.func.isRequired
}

PageCont.propTypes = {
  pages: PropTypes.array.isRequired,
  updatePoints: PropTypes.func.isRequired,
  nextQOb: PropTypes.func.isRequired,
  finishQ: PropTypes.func.isRequired
}

export default QuestionScreen
