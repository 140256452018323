import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import QIcon from '../QIcon/QIcon'
// import { useDispatch } from 'react-redux'
// import { changeQ } from '../../store/questionSlice'
import './menuItem.css'

const MenuItem = ({ qTitle, type, isLocked, qNum, qIndex, close, current, isFinished, questionNum, changeQuestion, isCurrentFloor }) => {
  return (
      <button
        disabled={isFinished}
        className={['menuItem', isLocked ? 'isLocked' : '', (current && isCurrentFloor) ? 'currentQ' : ''].join(' ')}
        onClick={() => changeQuestion(qIndex)}
      >
        {(type === 'mcq-multi' || type === 'mcq-single' || type === 'mcq-custom' || type === 'classify') && <QIcon num={questionNum} isFinished={isFinished} current={current && isCurrentFloor}/>}
        {type === 'photobooth' && <Icon type='camera' current={current && isCurrentFloor}/>}
        {type === 'info' && <QIcon num='!' current={current && isCurrentFloor} />}
        {/* {type === 'info' && <Icon type='info' />} */}
        <span className='questionText'> {qTitle} </span>
      </button>
  )
}

export default MenuItem

MenuItem.propTypes = {
  qTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  qIndex: PropTypes.number.isRequired,
  questionNum: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  qNum: PropTypes.number.isRequired,
  changeQuestion: PropTypes.func.isRequired,
  isCurrentFloor: PropTypes.bool
}
