import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit'
import qReducer from './questionSlice'
import appReducer from './appSlice'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  questions: qReducer,
  app: appReducer
})

const persistConfig = {
  key: 'sweetSouvenir',
  // whitelist: ['none'], // disable question store persistance for debug purpose
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})
