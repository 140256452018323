import React from 'react'
import PropTypes from 'prop-types'
import './icon.css'
import { ReactComponent as Ig } from '../../icns/gruppe-76.svg'

const Icon = ({ type }) => {
  switch (type) {
    case 'download':
      return <span className="icn-Icon-Download" />
    case 'document':
      return <span className="icn-Icon-Document" />
    case 'layer':
      return <span className="icn-Icon-Layer" />
    case 'chevronLeft':
      return <span className="icn-Icon-Chevron-Left" />
    case 'arrowDown':
      return <span className="icn-Icon-Arrow-Down" />
    case 'arrowLeft':
      return <span className="icn-Icon-Arrow-Left" />
    case 'arrowRight':
      return <span className="icn-Icon-Arrow-Right" />
    case 'arrowUp':
      return <span className="icn-Icon-Arrow-Up" />
    case 'chevronDown':
      return <span className="icn-Icon-Chevron-Down" />
    case 'chevronRight':
      return <span className="icn-Icon-Chevron-Right" />
    case 'chevronUp':
      return <span className="icn-Icon-Chevron-Up" />
    case 'like':
      return <span className="icn-Icon-Like" />
    case 'link':
      return <span className="icn-Icon-Link" />
    case 'shareTwitter':
      return <span className="icn-Icon-Share-Twitter" />
    case 'code':
      return <span className="icn-Icon-Code" />
    case 'help':
      return <span className="icn-Icon-Help" />
    case 'search':
      return <span className="icn-Icon-Search" />
    case 'camera':
      return <span className="icn-Icon-Camera" />
    case 'close':
      return <span className="icn-Icon-Close" />
    case 'settings':
      return <span className="icn-Icon-Settings" />
    case 'menu':
      return <span className="icn-Icon-Menu" />
    case 'info':
      return <span className="icn-Icon-Info" />
    case 'share':
      return <span className="icn-Icon-Share" />
    case 'shareFacebook':
      return <span className="icn-Icon-Share-Facebook" />
    case 'shareIg':
      return <Ig/>
    case 'add':
      return <span className="icn-Icon-Add" />
    case 'user':
      return <span className="icn-Icon-User" />
    default:
      return <>🐼</>
  }
}

export default Icon

Icon.propTypes = {
  type: PropTypes.string.isRequired
}
