import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Splash from '../../Components/Splash/Splash'
import { useSelector } from 'react-redux'
import { selectObStatus } from '../../store/appSlice'
const SplashScreen = () => {
  const [langPage, setlangPage] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      goToLang()
    }, 5000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  const history = useHistory()
  const onboarding = useSelector(selectObStatus)
  function nextPage () {
    history.push(onboarding ? 'quizz/1/1' : 'onboarding')
  }
  function goToLang () {
    setlangPage(true)
  }

  return (
    <Splash title={'Schoko-Quiz'} goToLang={goToLang} cta1act={nextPage} langPage={langPage}/>
  )
}

export default SplashScreen
