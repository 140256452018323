import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

const PageVariant = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { duration: 1.5, ...transition }
  }
}

function PageContainer ({ children }) {
  return (
      <motion.div
        variants={PageVariant}
        initial="initial"
        animate="enter"
        exit="exit"
        className='pageContainer'
      >
          {children}
      </motion.div>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContainer
