import React from 'react'
import PropTypes from 'prop-types'
import { pctPath } from '../../data/constants'
import './pct.css'
function Pictures (props, hasRings) {
  const { pct1, pct2, alt1 } = props
  return (
    <div className='pctCont'>
      {hasRings === true
        ? <div className='ringContainer'>

              <img src={`${pctPath}/${pct1}`} alt={alt1}/>
              {pct2 && <img src={`${pctPath}/${pct2}`} className='smallQPict'/>}
          <div className='ring'>
            &nbsp;
          </div>
        </div>
        : <>
            <img src={`${pctPath}/${pct1}`} />
            {pct2 && <img src={`${pctPath}/${pct2}`} className='smallQPict'/>}
        </>}
    </div>
  )
}

Pictures.propTypes = {
  pct1: PropTypes.string.isRequired,
  alt1: PropTypes.string,
  pct2: PropTypes.string,
  hasRings: PropTypes.bool
}

export default Pictures
