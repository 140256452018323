import React from 'react'
import Icon from '../Icon/Icon'
import './HintBt.css'
import { useSelector } from 'react-redux'
import { selectCommonKeys } from '../../store/appSlice'
export default function HintBt (props) {
  const cmn = useSelector(selectCommonKeys)
  return (
    <button
      type="button"
      className='hintButton button button-medium profile'
      {...props}
    >
      <Icon type='help' />
      <span className="hintLabed">{cmn.hint}</span>
    </button>
  )
}
