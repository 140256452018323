import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import {
  // changeQ,
  // selectCurrQ,
  // selectPoints,
  // nextQ,
  // selectFloorMenu,
  // selectIsFinished,
  // changeQ
  selectQuestions,
  updatePoints,
  selectFloorMenu,
  setFinished
} from '../../store/questionSlice'
import { useHistory } from 'react-router-dom'
import { findFirstUnansweredQuestion } from '../../utils'
import { PageCont } from '../QuestionScreen/QuestionScreen'
import PageHeader from '../../Components/PageHeader/PageHeader'

export default function QuizzComp ({ openMenu, urlParams }) {
  const questions = useSelector(selectQuestions)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isInvalid, setIsInvalid] = useState(false)
  const floorMenu = useSelector(selectFloorMenu)
  // const direction = 1

  function finishQ () {
    dispatch(setFinished({ floor: urlParams.floor, q: urlParams.q, points: 0 }))
  }
  function nextQ () {
    const nextQ = findFirstUnansweredQuestion(floorMenu, parseInt(urlParams.floor, 10) - 1)
    console.log(nextQ)
    if (nextQ) history.push(`/quizz/${nextQ.floor + 1}/${nextQ.q}`)
    else history.push('/final')
  }
  console.log('urlParams', urlParams)
  const { title, type } = floorMenu[parseInt(urlParams.floor - 1)].questions[parseInt(urlParams.q)]
  if (isNaN(parseInt(urlParams.floor, 10)) || isNaN(parseInt(urlParams.q, 10))) {
    history.push('/quizz/1/0')
  }
  let currType
  try {
    currType = floorMenu[urlParams.floor - 1].questions[urlParams.q].type
  } catch (e) {
    history.push('/quizz/1/0')
  }
  function upPoints (pts) {
    let qType = false
    switch (currType) {
      case 'mcq-single':
      case 'mcq-multi':
      case 'classify':
        qType = 'question'
        break
      case 'photobooth':
        qType = 'photobooth'
        break
      default:
        break
    }
    finishQ()
    dispatch(updatePoints({ pts, qType }))
  }

  if (currType === 'photobooth') {
    document.body.classList.add('photoBg')
  } else {
    document.body.classList.remove('photoBg')
  }

  useEffect(() => {
    if (urlParams.floor || urlParams.q) {
      const { floor, q } = urlParams
      if (isNaN(parseInt(floor, 10))) {
        console.log('invalid question / floor: NaN', floor, q)
        setIsInvalid(true)
      } else if (isNaN(parseInt(q, 10))) {
        console.log('invalid question / floor: NaN', floor, q)
        const nextQ = findFirstUnansweredQuestion(floorMenu, floor)
        console.log('invalid question going to the next available one', nextQ)
        if (nextQ) history.push(`/quizz/${nextQ.floor}/${nextQ.q}`)
        else history.push('/final')
      } else if (floor > floorMenu.length || q > floorMenu[floor - 1].questions.length) {
        console.log('invalid question / floor')
        setIsInvalid(true)
      }
    } else {
      console.log('no params or missing params')
      const nextQ = findFirstUnansweredQuestion(floorMenu, 0)
      console.log('invalid question going to the next available one', nextQ)
      if (nextQ) history.push(`/quizz/${nextQ.floor + 1}/${nextQ.q + 1}`)
      else history.push('/final')
    }
  }, [urlParams])

  if (isInvalid) return <div>invalid question / floor {JSON.stringify(urlParams)}</div>
  if (!urlParams) return <div>loading</div>
  return <div className='pagesContainer'>
    {/* {JSON.stringify(questions.questions[params.floor - 1].questions[params.q])} */}
    <PageHeader qNum={parseInt(urlParams.q)} title={title} type={type} openMenu={openMenu} />
    <div className="pagesCont">
      <PageCont pages={questions.questions[urlParams.floor - 1].questions[urlParams.q]} updatePoints={upPoints} nextQOb={nextQ} finishQ={finishQ}/>
    </div>
  </div>
}

QuizzComp.propTypes = {
  openMenu: PropTypes.func,
  urlParams: PropTypes.shape({
    floor: PropTypes.number,
    q: PropTypes.number
  }).isRequired
}
