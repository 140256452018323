import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../Components/Button/Button'
import Input from '../../Components/InputField/InputField'

import { useSelector, useDispatch } from 'react-redux'
import { selectKName, updateKName, selectCommonKeys } from '../../store/appSlice'
import Pictures from '../Pictures/Pictures'
import './NameSelection.css'
import { forbidenNames } from '../../data/constants'

let val = 0
function validateName (name) {
  if (!(name && (name !== null) && name.length > 0)) {
    console.log('name is invalid', name)
    return false
  }
  const isInvalid = forbidenNames.includes(name.toLowerCase())
  if (isInvalid) console.log('name is forbidden', name)
  return !isInvalid
}

function NameScreen ({ title, body, pct1, alt1, pct2, alt2, nextPage }) {
  const stName = useSelector(selectKName)
  const [name, setName] = useState(stName || '')
  const [_nameIsSet, setNameOk] = useState(stName && (stName !== null) && stName.length > 0)
  const [_hasInvalidName, setInvalid] = useState(false)
  const cmn = useSelector(selectCommonKeys)

  const dispatch = useDispatch()
  function updateName () {
    const isValid = validateName(name)
    if (isValid) {
      setNameOk(validateName(name))
      dispatch(updateKName(name))
    } else {
      setInvalid(true)
    }
  }
  function clearName () {
    setNameOk(false)
    setInvalid(false)
    dispatch(updateKName(null))
    ++val

    setName(null)
  }

  function navigate (to = 'social') {
    if (name.length > 0) nextPage()
  }
  return (
    <>
      {!_nameIsSet
        ? (
          <>
            {pct1 && (
              <Pictures pct1={pct1} alt1={alt1} />
            )}
            <div className="nameTxtCont">
              <h2>
                {title}
              </h2>
              <p>
                {body}
              </p>
              <Input val={name} updateVal={setName} key={val}/>
              <br/>
              <Button text={cmn.ob1_prim_cta} onClick={updateName} disabled={!name || name.length === 0 || _hasInvalidName }/>
              {name && name !== '' && <Button text={cmn.name_clear} onClick={clearName} nope/>}
            </div>
          </>
          )
        : (
          <>
            {pct2 && (
              <Pictures pct1={pct2} alt1={alt2} />
            )}
            <div className="nameTxtCont">
              <h2>
              {name}!
              </h2>
              <h2>
                {cmn.ob2_title}
              </h2>
              <p>
                {cmn.ob2_body}
              </p>
            <Button text={cmn.ob2_prim_cta} onClick={() => navigate('quizz')} disabled={name.length === 0 || _hasInvalidName}/>
            </div>
          </>
          )}
    </>
  )
}

NameScreen.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  pct1: PropTypes.string,
  alt1: PropTypes.string,
  pct2: PropTypes.string,
  alt2: PropTypes.string,
  nextPage: PropTypes.func.isRequired
}

export default NameScreen
