import { useEffect } from 'react'
import { clamp, distance } from '@popmotion/popcorn'

const buffer = 5

/**
 * import a js script and add it in the end of the html <body> tag
 *
 * @param {string} resourceUrl
 */
export const importScript = resourceUrl => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = resourceUrl
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [resourceUrl])
}

/**
 * extract quizz options items and decorate them with properties needed for the app
 *
 * @export {function}
 * @param {{}} data can have multiple form no descriptions yet
 * @param {string} _component the question type
 * @returns {{}} a decorated item object
 */
export function getItems (data, _component) {
  let items = false
  switch (_component) {
    case 'mcq-single':
    case 'mcq-custom':
    case 'photobooth':
      items = data.map((item, i) => {
        item.isChecked = false
        item.id = i
        item.isActive = true
        item._isRadio = true
        return item
      })
      return items
    case 'mcq-multi':
      items = data.map((item, i) => {
        item.isChecked = false
        item.id = i
        item.isActive = true

        return item
      })
      return items
    default:
      console.log('unknown comp type', _component)
      return items
  }
}

/**
 * find the new index of the moved array item
 *
 * @param {number} i index of the item to move
 * @param {number} yOffset offset to move
 * @param {{top: number, height: number}} positions
 * @returns {position} the new pos
 */

export const findIndex = (i, yOffset, positions) => {
  let target = i
  const { top, height } = positions[i]
  const bottom = top + height

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[i + 1]
    if (nextItem === undefined) return i

    const swapOffset =
        distance(bottom, nextItem.top + nextItem.height / 2) + buffer
    if (yOffset > swapOffset) target = i + 1

    // If moving up
  } else if (yOffset < 0) {
    const prevItem = positions[i - 1]
    if (prevItem === undefined) return i

    const prevBottom = prevItem.top + prevItem.height
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
    if (yOffset < -swapOffset) target = i - 1
  }

  return clamp(0, positions.length, target)
}

/**
 * shuffle an array
 *
 * @export
 * @param {array} array
 * @returns array
 */
export function shuffle (array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

/**
 * compare the solution array to the submition
 *
 * @export
 * @param {array} sol solution array (usually strings)
 * @param {array} arr tested array (usually strings)
 * @returns {{isOk:boolean, results:[number]}}
 */
export function compArr (sol, arr) {
  let isOk = true
  const results = sol.map((item, i) => {
    const r = sol[i] === arr[i] ? 1 : 0
    if (r === 0) isOk = false
    return r
  })
  return {
    isOk,
    results
  }
}

export function fillInValidator (input, answer) {
  const uInput = input.trim().toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s\s+/g, ' ')
  const cleanAnswer = answer.trim().toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s\s+/g, ' ')
  return uInput === cleanAnswer
}

export function translateQ (items, keys, t) {
  const translated = { ...items }
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index]
    if (typeof key === 'string') {
      if (translated[key]) translated[key] = t(translated[key])
    } else {
      if (Object.keys(key)) {
        const subKey = Object.keys(key)[0]
        translated[subKey].forEach(element => {
          key[subKey].forEach(item => {
            element[item] = t(element[item])
          })
        })
      }
    }
  }
  return translated
}

export function prepareData (data, currFloorData) {
  const totalQs = {
    qs: 0,
    fbs: 0
  }
  const floors = []
  data.forEach((floorData, i) => {
    const floorQ = floorData.questions
    floorData.isFinished = false
    floorData.totalQ = floorQ.length

    const floorMeta = {}
    floorMeta.floor = floorData.floor
    floorMeta.isFinished = false
    floorMeta.totalQ = floorQ.length
    floorMeta.questions = floorQ.map(q => {
      const qData = {}
      q.forEach((page, i) => {
        if (page.qTitle) qData.title = page.qTitle
        if (page._component !== 'page' && page._component !== 'result') {
          qData.type = page._component
          qData.isFinished = false
          qData.position = page.qNum
        }
        switch (page._component) {
          case 'mcq-single':
          case 'mcq-multi':
          case 'classify':
            ++totalQs.qs
            break
          case 'photobooth':
            ++totalQs.fbs
            break
          default:
            break
        }
      })
      return qData
    })
    floors.push(floorMeta)
  })
  const onBoarding = data.shift()
  const finalResult = data.pop()

  return { questions: data, onBoarding, finalResult, floorMenu: floors.slice(1, 4), totalQs }
}

export function prepareSimpleData (data) {
  const totalQs = {
    qs: 0,
    fbs: 0
  }
  const floors = []
  data.forEach(floorData => {
    const floorObj = {}
    const floorQ = floorData
    floorObj.isFinished = false
    floorObj.totalQ = floorQ.length

    const floorMeta = {}
    floorMeta.floor = floorQ[0].floor
    floorMeta.isFinished = false
    floorMeta.totalQ = floorQ.length
    floorMeta.questions = floorQ.map(q => {
      const qData = {}
      q.forEach((page, i) => {
        if (page.qTitle) qData.title = page.qTitle
        if (page._component !== 'page' && page._component !== 'result') {
          qData.type = page._component
          qData.isFinished = false
          qData.position = page.qNum
        }
        switch (page._component) {
          case 'mcq-single':
          case 'mcq-multi':
          case 'classify':
            ++totalQs.qs
            break
          case 'photobooth':
            ++totalQs.fbs
            break
          default:
            break
        }
      })
      return qData
    })
    floors.push(floorMeta)
  })
  const onBoarding = data.shift()
  const finalResult = data.pop()

  return { questions: data, onBoarding, finalResult, floorMenu: floors.slice(1, 4), totalQs }
}

export function getPlatform () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export function findFirstUnansweredQuestion (floorMenu, floorIndex = 0) {
  let startFloor = floorIndex
  let currentFloor = floorIndex

  if (floorIndex >= floorMenu.length) {
    startFloor = 1
    currentFloor = 1
  }

  do {
    const questions = floorMenu[currentFloor].questions
    for (let q = 0; q < questions.length; q++) {
      if (!questions[q].isFinished) {
        console.log('findFirstUnansweredQuestion', currentFloor, q)
        return { floor: parseInt(currentFloor, 10), q }
      }
    }

    // Move to the next floor, or wrap around to the first floor
    currentFloor = (currentFloor + 1) % floorMenu.length
  } while (currentFloor !== startFloor)

  return false
}
