import React from 'react'
import PropTypes from 'prop-types'
import './menuFootButton.css'
import Icon from '../Icon/Icon'
import ChampIcon from '../ChampIcon/ChampIcon'
import { MIN_PTS } from '../../data/constants'
export default function MenuFootButton ({ type, text, size, openPref, points }) {
  console.log('points', points, MIN_PTS, points > MIN_PTS)
  return (
    <button
      type="button"
      className={['menuFootButton', `button--${size}`].join(' ')}
      onClick={openPref}
    >
      <Icon type={type} />
      <span className='footText'> {text} </span>
      <ChampIcon win={points > MIN_PTS}/>
    </button>
  )
}

MenuFootButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  openPref: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired
}

MenuFootButton.defaultProps = {
  size: 'medium'
}
