import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from '../../Components/InputField/InputField'
import Button from '../../Components/Button/Button'
import { fillInValidator } from '../../utils'

function Fillin ({ answer, title, body, hint, cta1 }) {
  const [userInput, setUseInput] = useState('')
  const [isFinished, setFinished] = useState(false)
  const [isPassed, setPassed] = useState(false)
  function validate () {
    if (!answer) {
      setPassed(true)
      setFinished(true)
    } else {
      fillInValidator(userInput, answer) && setPassed(true)
      setFinished(true)
    }
  }
  return (
    <div>
      <h1>{ title }</h1>
      {body && <p>{body}</p>}
      <Input val={userInput} updateVal={setUseInput} />
      <Button text={cta1} onClick={validate} disabled={userInput.length === 0 || isFinished}/>
      {isFinished && (isPassed ? 'you win' : `you loose, answer was ${answer}`)}
    </div>
  )
}

Fillin.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  hint: PropTypes.string,
  cta1: PropTypes.string.isRequired,
  answer: PropTypes.string
}

export default Fillin
