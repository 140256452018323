import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  // changeQ,
  selectOnboarding,
  // selectPoints,
  // nextQ,
  updatePoints
  // selectFloorMenu
} from '../../store/questionSlice'
import { setObStatus, selectObStatus, selectKName, selectCommonKeys } from '../../store/appSlice'
import { motion, AnimatePresence } from 'framer-motion'
import QuestionDisplay from '../../Components/QuestionDisplay/QuestionDisplay'
import Button from '../../Components/Button/Button'
// import PageHeader from '../../Components/PageHeader/PageHeader'

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

function IndexScreen () {
  const qState = useSelector(selectOnboarding)
  const dispatch = useDispatch()
  const [currQ, setCurrQ] = useState(0)
  const history = useHistory()
  const obStatus = useSelector(selectObStatus)
  const kname = useSelector(selectKName)
  const cmn = useSelector(selectCommonKeys)
  function toQuizz () {
    history.push('quizz')
  }
  // console.log('totalPoints', points)
  const currQData = qState.questions[currQ]
  // const currFloorLen = qState.questions[floor].questions.length
  // const currType = .type
  function upPoints (pts) {
    dispatch(updatePoints({ pts, qType: 'question' }))
  }
  // console.log('qType', floorMenu[floor].questions[currQ].type)
  function nextQ () {
    if (currQ < qState.questions.length - 1) {
      setCurrQ(currQ + 1)
    } else {
      toQuizz()
      dispatch(setObStatus(true))
    }
  }
  // console.log('currFloorLen', currFloorLen, 'current q', currQ)
  const direction = 1
  return (
    <>{obStatus
      ? <div className='pagesContainer'>
      <p>{cmn.ob_finished}</p>
      <Button text={cmn.ob_finished_cta} onClick={toQuizz}/>
    </div>
      : <div className='pagesContainer'>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            variants={variants}
            initial="exit"
            custom={direction}
            animate="center"
            exit="exit"
            className='pageCont'
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}
            key={`page-${1}`}>
            <PageCont pages={currQData} updatePoints={upPoints} nextQOb={nextQ} kname={kname}/>
          </motion.div>
        </AnimatePresence>
      </div>
    }

    </>
  )
}

function PageCont ({ pages, updatePoints, nextQOb, kname }) {
  const [qPoints, setQpoints] = useState(0)
  const [currPage, setCurrPage] = useState(0)
  const cPageData = pages[currPage]
  const [isOver, setOver] = useState(false)
  const [success, setSuccess] = useState(0)
  function nextPage () {
    if (currPage < pages.length - 1) {
      setCurrPage(currPage + 1)
    } else {
      nextQOb()
      setCurrPage(0)
    }
  }
  // console.log(pages)
  return <QuestionDisplay {...cPageData}
    setOver={setOver}
    nextPage={nextPage}
    setSuccess={setSuccess}
    points={qPoints}
    setQpoints={setQpoints}
    kname={kname}
    updatePoints={updatePoints}
    nextQOb={nextQOb}
    isOver={isOver}
    success={success}/>
}

PageCont.propTypes = {
  pages: PropTypes.array.isRequired,
  updatePoints: PropTypes.func.isRequired,
  kname: PropTypes.string,
  nextQOb: PropTypes.func
}

export default IndexScreen
