import * as React from 'react'
import PropTypes from 'prop-types'
const ChampIcon = ({ win }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <path fill={win ? '#7db700' : 'rgb(37, 48, 129)'} d="M24 3c-.372 4.105-2.808 8.091-6.873 9.438.297-.552.596-1.145.882-1.783 2.915-1.521 4.037-4.25 4.464-6.251h-2.688c.059-.45.103-.922.139-1.405H24zM0 3c.372 4.105 2.808 8.091 6.873 9.438a27.473 27.473 0 0 1-.882-1.783c-2.915-1.521-4.037-4.25-4.464-6.251h2.688A26.405 26.405 0 0 1 4.077 3H0zm16.921 0c-.368 4.506-1.953 7.23-3.372 9.669-.577.993-1.136 1.953-1.543 2.95-.408-.998-.969-1.959-1.548-2.953C9.036 10.229 7.447 7.505 7.079 3h9.842zM19 1H5c0 9.803 5.105 13.053 5.604 17h2.805C13.906 14.053 19 10.803 19 1zm-5.562 18h-2.866c-.202 1.187-.63 2.619-2.571 2.619V23h8v-1.381c-1.999 0-2.371-1.432-2.563-2.619zM9.794 6.528l1.46-.202L11.897 5l.643 1.326 1.46.202-1.063 1.021.26 1.451-1.3-.695-1.3.695.26-1.451-1.063-1.021z" />
  </svg>
)
export default ChampIcon

ChampIcon.propTypes = {
  win: PropTypes.bool
}
