export const pctPath = '/pct'
export const colors = {
  primary_default: '#253081',
  primary_active: '#161d4f',
  secondary_default: '#c6a76e',
  white: '#fff',
  background_default: '#fff8e6',
  text_default: '#43201d',
  secondary_active: '#947b51',
  grey: '#aaaaaa'
}

export const forbidenNames = ['3-reich',
  '3reich',
  'afd',
  'heil',
  'hitler-hitler',
  'hitler',
  'hitlerbart',
  'hitler-bart',
  'hitler bart',
  'hitler-deutschland',
  'hitlerdeutschland',
  'fuehrer-deutschland',
  'fuehrerdeutschland',
  'fuhrer-deutschland',
  'fuhrerdeutschland',
  'nazi',
  'nazi-deutschland',
  'nazi-reich',
  'nazideutschland',
  'nazireich',
  'adolf',
  'adolf hitler'
]

export const RAFFLE_URL = 'https://www.schokoladenmuseum.de/de/quiz-gewinn'

export const MIN_PTS = 20
