import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import { useSelector } from 'react-redux'
import { selectCommonKeys } from '../../store/appSlice'

const FinalPage = ({ pts, title, body, cta1act }) => {
  document.body.classList.remove('photoBg')
  const cmn = useSelector(selectCommonKeys)
  console.log(cmn)
  return <div className='pgCont final pagesContainer'>
      <div className='pgBody'>
        <div className="scoreCont">
          <img src="/pct/result_bg.png"/>
          <div className="scoreTxTcont">
            <div className='scoreHLine'>{cmn.you_have}</div>
            <div className='scoreTxt'>{pts}</div>
            <div className='scoreHLine'>{cmn.cacao_beans}</div>
          </div>
        </div>
        <div>
          <h2>{title}</h2>
          <p>{body}</p>
        </div>
        </div>
        <footer className='pgFooter'>
          {cta1act && <Button text={cmn.your_profile} profileBt profile onClick={cta1act}/>}
        </footer>

    </div>
}

FinalPage.propTypes = {
  pts: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cta1act: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired
}

export default FinalPage
