import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Diamond } from '../../icns/counter2.svg'
import { ReactComponent as CheckMark } from '../../icns/checkmark.svg'
import './QIcon.css'
export default function QIcon ({ current, num, isFinished }) {
  return (
    <span className="qIconCont">
      <Diamond className={['diamond', current ? 'curr' : ''].join(' ')}/>
      <span className="numCont">{isFinished ? <CheckMark/> : num}</span>
    </span>
  )
}

QIcon.propTypes = {
  current: PropTypes.bool,
  isFinished: PropTypes.bool,
  num: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
