import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectFloorMenu, selectCurrFloor, selectCurrQ, selectTotalQs, selectPoints } from '../../store/questionSlice'
import './menuOverlay.css'
// import OverlayHeader from '../OverlayHeader/OverlayHeader'
import MenuFootButton from '../MenuFootButton/MenuFootButton'
import FloorMenu from '../FloorMenu/FloorMenu'
import Icon from '../Icon/Icon'
// import Hint from '../Hint/Hint'
import Profile from '../Profile/Profile'
import { selectCommonKeys } from '../../store/appSlice'
import { withRouter } from 'react-router-dom'

// const variantsZ = {
//   open: { x: 0, zIndex: 50 },
//   closed: { x: '100%', zIndex: -1 }
// }
const variants = {
  open: { x: 0, position: 'fixed' },
  closed: { x: '100%', position: 'absolute' }
}
// const prefVariant = {
//   open: { x: 0 },
//   closed: { x: '-100%' }
// }

export function MenuOverlayComp ({ isOpen, close, history }) {
  const [prefOpen, setPrefOpen] = useState(false)
  // const [nextFloor, setNextFloor] = useState(false)
  function openPref () {
    setPrefOpen(true)
  }
  function closePref () {
    setPrefOpen(false)
  }
  const cmn = useSelector(selectCommonKeys)
  const currFloor = useSelector(selectCurrFloor)
  const currQ = useSelector(selectCurrQ)
  const floorMenu = useSelector(selectFloorMenu)
  const totalQ = useSelector(selectTotalQs)
  const points = useSelector(selectPoints)

  return floorMenu && cmn
    ? (
    <motion.div
      className="overlayCont"
      animate={isOpen ? 'open' : 'closed'}
      transition={{ type: 'tween' }}
      variants={variants}
      initial='closed'
    >
      {/* {nextFloor && <Hint title={t('change_floor_title')} text={t('change_floor_warning')} isOpen={!!nextFloor} closeFunc={closeFloorHint} cta1act={forceNextFloor} cta1txt={t('next_floor_confirm')}/>} */}
      <div className="mnu">
        <div className='overlyHeaderCont'>
          <div className="menuNav">
            <span className={['floorBt', currFloor === 0 ? 'currF' : ''].join(' ')}>{cmn.site_title}</span>
          </div>
          <button onClick={close} className="closeBt">
            <Icon type='close' />
          </button>
        </div>
        <div className="menuItemsCont">
          <div className="content">
            {floorMenu && floorMenu.map((floor, index) => {
              return <div key={`floor-${index}`}>
                <div className="ttl">
                  <div className="overlyHeaderCont">
                    <div className="menuNav">
                      <Icon type='layer'/>
                      <span className={['floorBt', currFloor === index ? 'currF' : ''].join(' ')} >{cmn[`floor_${index}`]}</span>
                    </div>
                  </div>
                  <FloorMenu
                    menuData={floor.questions}
                    close={close}
                    currQ={currQ}
                    currFloor={index}
                    isCurrentFloor={currFloor === index}
                    history={history} />
                </div>
            </div>
            })}
            {/* <FloorMenu menuData={floorMenu[currFloor].questions} close={close} currQ={currQ}/> */}
          </div>
          {/* <div>
            current floor: {currFloor}, currentQ: {currQ}
          </div> */}
        </div>
        <div className='overlayFooter'>
          <MenuFootButton type='user' text={cmn.your_profile} openPref={openPref} points={points} />
        </div>
      </div>
      <Profile
        qTotal={totalQ.tqs}
        qFinished={totalQ.uQs}
        fTotal={totalQ.tPbs}
        fFinished={totalQ.uPbs}
        prefOpen={prefOpen}
        points={points}
        closePref={closePref}
      />
    </motion.div>
      )
    : null
}

MenuOverlayComp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const MenuOverlay = withRouter(MenuOverlayComp)

export default MenuOverlay
