import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './inputField.css'
function InputField ({ val, updateVal }) {
  const [initVal, setVal] = useState(val || '')
  function updateValue (value) {
    updateVal(value)
    setVal(value)
  }

  return (
    <div>
      <input type='text' value={initVal} onChange={(e) => updateValue(e.target.value)} className='txtInput' placeholder='z.b. Bonnie'/>
    </div>
  )
}

InputField.propTypes = {
  updateVal: PropTypes.func.isRequired,
  val: PropTypes.string
}

export default InputField
