import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectPoints, selectTotalQs } from '../../store/questionSlice'
import Profile from '../../Components/Profile/Profile'

const PrefScreen = ({ openMenu }) => {
  const pts = useSelector(selectPoints)
  const totalQ = useSelector(selectTotalQs)
  const history = useHistory()
  function closeProfile () {
    history.push('quizz')
  }
  return (
    <div>
      <Profile
        qTotal={totalQ.tqs}
        qFinished={totalQ.uQs}
        fTotal={totalQ.tPbs}
        fFinished={totalQ.uPbs}
        prefOpen={true}
        points={pts}
        closePref={closeProfile}
      />
    </div>
  )
}

PrefScreen.propTypes = {
  openMenu: PropTypes.func.isRequired
}

export default PrefScreen
