import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectPoints } from '../../store/questionSlice'
import FinalPage from '../../Components/FinalPage/FinalPage'
import { selectCommonKeys } from '../../store/appSlice'

const FinalScreen = ({ openMenu }) => {
  const pts = useSelector(selectPoints)
  const cmn = useSelector(selectCommonKeys)
  const history = useHistory()

  function toProfile () {
    history.push('profile')
  }

  const pageProps = {
    pts,
    title: cmn.final_title,
    body: cmn.final_text,
    openMenu,
    cta1act: toProfile
  }
  return (
    <div>
      <FinalPage {...pageProps} />
    </div>
  )
}

FinalScreen.propTypes = {
  openMenu: PropTypes.func.isRequired
}

export default FinalScreen
