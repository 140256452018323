import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import './PageHeader.css'
import QIcon from '../QIcon/QIcon'

const PageHeader = ({ title, type, qNum, openMenu }) => {
  return (
    <nav className='questionHeader'>
      <div className="menuNav">
        {(type === 'mcq-multi' || type === 'mcq-single' || type === 'classify' || type === 'mcq-custom') && <QIcon num={qNum} current/>}
        {type === 'photobooth' && <Icon type='camera' />}
        {type === 'info' && <Icon type='info' />}
        <span className="CopySmall qTitle">
          {title}
        </span>
      </div>
      <button onClick={openMenu} className="closeBt">
        <Icon type='menu' />
      </button>
    </nav>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['mcq-multi', 'mcq-single', 'classify', 'photobooth', 'info', 'fillin', 'mcq-custom']).isRequired,
  openMenu: PropTypes.func.isRequired,
  qNum: PropTypes.number
}

PageHeader.defaultProps = {

}

export default PageHeader
